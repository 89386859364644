@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --text-50: #f2f2f3;
    --text-100: #e5e6e6;
    --text-200: #cbcccd;
    --text-300: #b1b3b4;
    --text-400: #97999b;
    --text-500: #7d8082;
    --text-600: #646668;
    --text-700: #4b4d4e;
    --text-800: #323334;
    --text-900: #191a1a;
    --text-950: #0c0d0d;

    --background-50: #f1edf7;
    --background-100: #e3dcef;
    --background-200: #c7b8e0;
    --background-300: #ac95d0;
    --background-400: #9071c1;
    --background-500: #744eb1;
    --background-600: #5d3e8e;
    --background-700: #462f6a;
    --background-800: #2e1f47;
    --background-900: #171023;
    --background-950: #0c0812;

    --primary-50: #edeff8;
    --primary-100: #dadff1;
    --primary-200: #b5bfe3;
    --primary-300: #909fd5;
    --primary-400: #6b7fc7;
    --primary-500: #465fb9;
    --primary-600: #384c94;
    --primary-700: #2a396f;
    --primary-800: #1c264a;
    --primary-900: #0e1325;
    --primary-950: #070912;

    --secondary-50: #eef0f7;
    --secondary-100: #dde1ee;
    --secondary-200: #bbc4dd;
    --secondary-300: #98a6cd;
    --secondary-400: #7689bc;
    --secondary-500: #546bab;
    --secondary-600: #435689;
    --secondary-700: #324067;
    --secondary-800: #222b44;
    --secondary-900: #111522;
    --secondary-950: #080b11;

    --accent-50: #e9eefb;
    --accent-100: #d4def7;
    --accent-200: #a8bdf0;
    --accent-300: #7d9be8;
    --accent-400: #527ae0;
    --accent-500: #2659d9;
    --accent-600: #1f47ad;
    --accent-700: #173582;
    --accent-800: #0f2457;
    --accent-900: #08122b;
    --accent-950: #040916;
  }
  .dark {
    --text-50: #0c0d0d;
    --text-100: #191a1a;
    --text-200: #323334;
    --text-300: #4b4d4e;
    --text-400: #646668;
    --text-500: #7d8082;
    --text-600: #97999b;
    --text-700: #b1b3b4;
    --text-800: #cbcccd;
    --text-900: #e5e6e6;
    --text-950: #f2f2f3;

    --background-50: #0a0b0f;
    --background-100: #15171e;
    --background-200: #292d3d;
    --background-300: #3e445b;
    --background-400: #535a79;
    --background-500: #677198;
    --background-600: #868dac;
    --background-700: #a4aac1;
    --background-800: #c2c6d6;
    --background-900: #e1e3ea;
    --background-950: #f0f1f5;

    --primary-50: #0a0c10;
    --primary-100: #131920;
    --primary-200: #27323f;
    --primary-300: #3a4b5f;
    --primary-400: #4e647e;
    --primary-500: #617c9e;
    --primary-600: #8197b1;
    --primary-700: #a0b1c5;
    --primary-800: #c0cbd8;
    --primary-900: #dfe5ec;
    --primary-950: #eff2f5;

    --secondary-50: #011917;
    --secondary-100: #01322f;
    --secondary-200: #02645d;
    --secondary-300: #03968c;
    --secondary-400: #04c8bb;
    --secondary-500: #05faea;
    --secondary-600: #37fbee;
    --secondary-700: #69fcf2;
    --secondary-800: #9bfdf6;
    --secondary-900: #cdfefb;
    --secondary-950: #e6fefd;

    --accent-50: #081211;
    --accent-100: #102322;
    --accent-200: #1f4745;
    --accent-300: #2f6a67;
    --accent-400: #3e8e8a;
    --accent-500: #4eb1ac;
    --accent-600: #71c1bd;
    --accent-700: #95d0cd;
    --accent-800: #b8e0de;
    --accent-900: #dcefee;
    --accent-950: #edf7f7;
  }
}

.swiper-pagination-custom-bullet {
  transition: all;
  transition-duration: 150ms;
  display: inline-block;
  margin: 8px;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background-color: var(--background-100);
}

.swiper-pagination-custom-bullet-active {
  transition: all;
  transition-duration: 150ms;
  border-radius: 33px;
  width: 32px;
  background-color: var(--secondary-500);
}
